import { motion } from "framer-motion";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UseCasesDetail.css";

const UseCasesDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	console.log(location, "loaction");

	const {
		endReports = [], // Default to an empty array if undefined
		customerLogo,
		useCaseName,
		reportCategory,
		listOfReportCategories,
	} = location.state || {};

	const [selectedCategory, setSelectedCategory] = useState(null); // State to track selected category

	// Function to handle report click
	const handleReportClick = (dashboardName, embedCode) => {
		navigate(`/dashboard/${dashboardName}/${embedCode}`);
	};

	// Function to handle category click and toggle the display of reports
	const handleCategoryClick = (category) => {
		setSelectedCategory(
			(prevCategory) => (prevCategory === category ? null : category) // Toggle the selected category
		);
	};

	const reportCardVariants = {
		initial: { opacity: 0, y: 50 },
		animate: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.6,
				ease: "easeOut",
				delay: 0.2,
			},
		},
		hover: {
			scale: 1.05,
			transition: { duration: 0.1, ease: "easeInOut" },
		},
		exit: {
			opacity: 0,
			y: -50,
			transition: { duration: 0.1, ease: "easeIn" },
		},
	};

	return (
		<div>
			<div className="usercaseBackgr">
				<div className="flex-userTitle">
					<div className="nav-bar">
						<div className="w-75">
							<img
								className="Business-logo"
								src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
								alt="Business Logo"
							/>
						</div>
						<img
							className="right-side-logo"
							src={customerLogo}
							alt="Customer Logo"
						/>
					</div>
					<div
						className="subredarrowBackBtn d-flex align-items-center"
						onClick={() => navigate(-1)}
					>
						<i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
						<p className="BackBtn p-0 m-0">Back</p>
					</div>
					<div className="useCaseName-display">
						<p className="text-white userCase-p">
							{reportCategory}
						</p>
					</div>
				</div>
			</div>

			<div className="usercaseLowerBack-usercase">
				{endReports.length > 0 && (
					<div className="endReports-card">
						{endReports.map((report, index) => (
							<motion.div
								key={`endReports-${index}`}
								className={`report-category-card filled-icon ${
									index % 2 === 0 ? "card-alt-color" : ""
								}`}
								onClick={() =>
									handleReportClick(
										report.reportName.replaceAll(" ", "-"),
										report.embedCode
									)
								}
								initial="initial"
								animate="animate"
								whileHover="hover"
								exit="exit"
								variants={reportCardVariants}
							>
								<div className="category-card-below">
									{report.reportIcon ? (
										<img
											className="report-category-icon"
											src={report.reportIcon}
											alt={`Icon for ${report.reportName}`}
										/>
									) : (
										<p className="empty-category-text p-0 m-0">
											{report.reportName}
										</p>
									)}
									<p className="report-category-name">
										{report.reportName}
									</p>
								</div>
							</motion.div>
						))}
					</div>
				)}

				{listOfReportCategories &&
					listOfReportCategories.length > 0 && (
						<div className="endReports-card">
							{listOfReportCategories.map((category, index) => (
								<motion.div
									key={`listOfReportCategories-${index}`}
									className={`report-category-card filled-icon-user-case ${
										index % 2 === 0
											? "card-alt-color-user-case"
											: ""
									}`}
									onClick={() =>
										handleCategoryClick(category)
									}
									initial="initial"
									animate="animate"
									whileHover="hover"
									exit="exit"
									variants={reportCardVariants}
								>
									<div className="category-card-below">
										<div className="ThousandEyes">
											<div>
												<img
													className="report-category-icon-user-case"
													src={category.reportIcon}
													alt={`Icon for ${category.reportIcon}`}
												/>
											</div>
											<div>
												<p className="report-category-name">
													{category.reportCategory}
												</p>
											</div>
										</div>
									</div>
								</motion.div>
							))}
						</div>
					)}

				{selectedCategory && (
					<div className="endReports-card">
						{selectedCategory.endReports &&
						selectedCategory.endReports.length > 0 ? (
							selectedCategory.endReports.map((report, index) => (
								<motion.div
									key={`selectedReport-${index}`}
									className={`report-category-card selectedCategory-card filled-icon selected-card ${
										index % 2 === 0 ? "card-alt-color" : ""
									}`}
									onClick={() =>
										handleReportClick(
											report.reportName.replaceAll(
												" ",
												"-"
											),
											report.embedCode
										)
									}
									initial="initial"
									animate="animate"
									whileHover="hover"
									exit="exit"
									variants={reportCardVariants}
								>
									<div className="selectedCategory-card-below">
										<p className="selectedCategory-category-name p-0 m-0">
											{report.reportName}
										</p>
									</div>
								</motion.div>
							))
						) : (
							<p></p>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UseCasesDetail;
