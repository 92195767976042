import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./dashboardview.css";


const DashboardView = () => {
  const { embedCode } = useParams();
  const navigate = useNavigate();

  document.title = `Dashboard`;

  const handleGoBack = () => {
    navigate(-1);
  };
  
  return (
    <div>
      <div className="dashboard-nav-bar d-flex justify-content-between align-items-center">
        <div>
          <div
            className="Dashbord-Back-Btn d-flex align-items-center"
            onClick={handleGoBack}
          >
            <i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
            <p className="BackBtn p-0 m-0">Back</p>
          </div>
        </div>
       
        <div>
          <div className="w-75">
            <img
              className="Business-logo"
              src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
              alt="Business Logo"
            />
          </div>
        </div>
      </div>

      <div className="iframe-container">
        {embedCode ? (
          <iframe
            src={`https://embed.domo.com/embed/pages/${embedCode}`}
            allowFullScreen={true}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            crossOrigin="anonymous"
          ></iframe>
        ) : (
          <p>Embed code not provided.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardView;
