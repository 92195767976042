import L from "leaflet";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import europeGeoData from "../../LocalData/europegeo.json";
import "./NewRegionPage.css";

const NewRegionPage = () => {
    const { region } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [subRegions, setSubRegions] = useState(
        location.state?.subRegions || []
    );
    const mapRef = useRef(null);
    const mapInstance = useRef(null);

    const highlightTextRef = useRef(null);

    const handleSubRegionClick = useCallback(
        (subRegion) => {
            if (subRegion.enabled) {
                // Ensure smooth transition by using a slight delay or a different navigate method
                navigate(`/regions/${region}/subregion/${subRegion.code}`, {
                    state: {
                        listOfCustomers: subRegion.listOfCustomers || [],
                    },
                    replace: true, 
                });
                
            }
        },
        [navigate, region]
    );

    useEffect(() => {
        const capitalizedRegion = region.charAt(0).toUpperCase() + region.slice(1);
        document.title = `${capitalizedRegion} `;
      }, [region]);

    useEffect(() => {
        console.log("Location State:", location.state);
    
        if (location.state?.subRegions) {
          setSubRegions(location.state.subRegions);
        }
    
        const mapContainer = mapRef.current;
    
        if (!mapContainer) {
          console.error("Map container is not available!");
          return;
        }
        if (mapInstance.current) {
          mapInstance.current.remove();
          mapInstance.current = null;
        }
    
        // Initialize the map with a center and zoom level
        mapInstance.current = L.map(mapContainer).setView([56.596, 15.2551], 3); // Initial zoom level is 3
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
          {
            attribution: '&copy; <a href="https://carto.com/">CARTO</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 18,
          }
        ).addTo(mapInstance.current);
    

        mapInstance.current.setZoom(3.5);
    
        const geojsonData = europeGeoData;
    
        const displayedClassNames = new Set();
    
        // GeoJSON layer
        const geoJsonLayer = L.geoJSON(geojsonData, {
          onEachFeature: function (feature, layer) {
            const originalStyle = {
              color: "gray",
              weight: 1,
            };
    
            const handleMouseOver = (e) => {
              if (!feature.properties.CLASS) return;
    
              const hoveredClass = feature.properties.CLASS;
    
              const matchingRegion = subRegions.find(
                (subRegion) => subRegion.region === hoveredClass
              );
    

              if (matchingRegion?.enabled) {
                geoJsonLayer.eachLayer(function (otherLayer) {
                  if (otherLayer.feature.properties.CLASS === hoveredClass) {
                    otherLayer.setStyle({
                      color: "rgba(255, 120, 0, 0.8)", 
                      weight: 3,
                    });
                  }
                });
    
                if (highlightTextRef.current) {
                  highlightTextRef.current.innerHTML =
                    feature.properties.CLASS || "Highlighted Region";
                  highlightTextRef.current.style.display = "block";
                }
              } else {
                geoJsonLayer.eachLayer(function (otherLayer) {
                  if (otherLayer.feature.properties.CLASS === hoveredClass) {
                    otherLayer.setStyle({
                      color: "white", 
                      weight: 1,
                    });
                  }
                });
    
                if (highlightTextRef.current) {
                  highlightTextRef.current.innerHTML =
                    "Region Disabled";
                  highlightTextRef.current.style.display = "block";
                }
              }
            };
    
            const handleMouseOut = (e) => {
              geoJsonLayer.eachLayer(function (otherLayer) {
                otherLayer.setStyle(originalStyle);
              });
    
              if (highlightTextRef.current) {
                highlightTextRef.current.style.display = "none";
              }
            };
    
            layer.on("mouseover", handleMouseOver);
            layer.on("mouseout", handleMouseOut);
    
            const onClick = () => {
              const subRegion = subRegions.find(
                (subRegion) => subRegion.region === feature.properties.CLASS
              );
              if (subRegion) {
                handleSubRegionClick(subRegion);
              }
            };
    
            layer.on("click", onClick);
    
            const countryName = feature.properties.CLASS;
    
            if (countryName && !displayedClassNames.has(countryName)) {
              displayedClassNames.add(countryName);
    
              const center = layer.getBounds().getCenter();
    
              const label = L.marker([center.lat, center.lng], {
                icon: L.divIcon({
                  className: "simple-label",
                  html: `<div class="simple-label-text">${countryName}</div>`,
                  iconSize: [150, 50],
                  iconAnchor: [75, 25],
                }),
              }).addTo(mapInstance.current);
    
              label.on("click", onClick);  
              label.on("mouseover", handleMouseOver);
              label.on("mouseout", handleMouseOut);
    
              const updateLabelSize = () => {
                const zoomLevel = mapInstance.current.getZoom();
                let fontSize = "35px";
    
                if (zoomLevel <= 3) {
                  fontSize = "10px";
                } else if (zoomLevel <= 5) {
                  fontSize = "20px";
                } else if (zoomLevel <= 7) {
                  fontSize = "12px";
                } else if (zoomLevel <= 9) {
                  fontSize = "16px";
                } else {
                  fontSize = "18px";
                }
    
                label
                  .getElement()
                  .querySelector(".simple-label-text")
                  .style.fontSize = fontSize;
              };
    
              mapInstance.current.on("zoom", updateLabelSize);
              updateLabelSize();
            }
          },
          style: function (feature) {
            return {
              color: "gray",
              weight: 1,
            };
          },
        }).addTo(mapInstance.current);
    
        // Fit the map bounds to the GeoJSON layer
        mapInstance.current.fitBounds(geoJsonLayer.getBounds());
    
        return () => {
          // Clean up when component unmounts
          if (mapInstance.current) {
            mapInstance.current.remove();
            mapInstance.current = null;
          }
        };
      }, [subRegions, europeGeoData, location.state?.subRegions, handleSubRegionClick]);

    const handleGoBack = () => {
        navigate("/", { replace: true }); // Smooth back navigation
    };

    return (
        <div className="background-img">
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between align-items-start w-25 left-side">
                        <div>
                            <img
                                id="first-logo"
                                className="Business-logo"
                                src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
                                alt="Orange Business Logo"
                            />
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-between">
                            <p className="p-0 m-0 text-white global">GLOBAL</p>
                            <p className="p-0 m-0 text-white single-text">
                                SINGLE PANE OF GLASS
                            </p>
                            <div>
                                <img
                                    src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+Global+SPOG+dashboard+-+assets/slice2.png"
                                    alt="Slice"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-75 region-right-side">
                        <div className="orange-border">
                            <div className="map-background">
                                <div ref={mapRef} id="map"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewRegionPage;
