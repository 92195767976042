import React from "react";
import { Route, createRoutesFromElements } from "react-router-dom";
import Customer from "../Components/Customer/customer";
import DashboardView from "../Components/DashboardView/dashboardview";
import Home from "../Components/HomePage/homepage";
import Login from "../Components/Login/login";
import RegionPage from "../Components/RegionPage/RegionPage";
import Subregionpage from "../Components/SubRegionPage/subregionpage";
import UseCases from "../Components/UseCases/UseCases";
import UseCasesDetail from "../Components/UseCasesDetail/UseCasesDetail";
import ProtectedRoute from "./ProtectedRoute";
import NewRegionPage from "../Components/NewRegionPage/NewRegionPage";
import BackendData from "../Components/backend/BackendData";

function RegiousRouter() {
	return createRoutesFromElements(
		<>
			<Route path="/login" element={<Login />} />
			<Route path="/backend/data" element={<BackendData/>}/>
			<Route
				path="/"
				element={
					<ProtectedRoute>
						<Home />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/regions/:region"
				element={
					<ProtectedRoute>
						<RegionPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/regions/:region/subregion/:code"
				element={
					<ProtectedRoute>
						<Subregionpage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/regions/:region/subregion/:code/:customer"
				element={
					<ProtectedRoute>
						<Customer />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/regions/:region/subregion/:code/:customer/:useCaseName"
				element={
					<ProtectedRoute>
						<UseCases />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/regions/:region/subregion/:code/:customer/:useCaseName/:cardname"
				element={
					<ProtectedRoute>
						<UseCasesDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/dashboard/:dashboardName/:embedCode"
				element={
					<ProtectedRoute>
						<DashboardView />
					</ProtectedRoute>
				}
			/>{" "}
			<Route
				path="/NewRegionPage/:region"
				element={
					<ProtectedRoute>
							<NewRegionPage />
					</ProtectedRoute>
					
				} />
				
		</>
	);
}

export default RegiousRouter;
