import React, { useEffect, useState } from "react";
import regionsData from "../../LocalData/data.json";
import "./BackendData.css"; // Custom CSS for styling

const BackendData = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(regionsData);
        console.log(regionsData, "regionsData");
    }, []);

    const renderTableData = (data) => {
        const renderRows = (data) => {
            return Object.keys(data).map((key, idx) => {
                const value = data[key];
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return (
                        <tr key={idx}>
                            <td className="table-header">{key}</td>
                            <td>
                                <table className="nested-table">
                                    <tbody>{renderRows(value)}</tbody>
                                </table>
                            </td>
                        </tr>
                    );
                } else if (Array.isArray(value)) {
                    return (
                        <tr key={idx}>
                            <td className="table-header">{key}</td>
                            <td>
                                <ul className="nested-list">
                                    {value.map((item, idx2) => (
                                        <li key={idx2}>
                                            <table className="nested-table">
                                                <tbody>{renderRows(item)}</tbody>
                                            </table>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={idx}>
                            <td className="table-header">{key}</td>
                            <td>{value}</td>
                        </tr>
                    );
                }
            });
        };

        return (
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>{renderRows(data)}</tbody>
            </table>
        );
    };

    return (
        <div className="backend-data">
            <h2 className="title">Regions Data</h2>
            {data ? (
                <div className="table-wrapper">
                    {renderTableData(data)}
                </div>
            ) : (
                <p className="loading-text">Loading data...</p>
            )}
        </div>
    );
};

export default BackendData;
