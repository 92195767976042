import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// CSS
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RegiousRouter from "./Routes/RegionsRouter";
import "leaflet/dist/leaflet.css";
const appRouter = createBrowserRouter(RegiousRouter());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);

reportWebVitals();
