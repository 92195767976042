import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./RegionPage.css";

const RegionPage = () => {
    const { region } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const subRegions = location.state?.subRegions || [];

    useEffect(() => {
        if (!location.state) {
            return navigate('/', { replace: true });
        }
    }, [location, navigate]);

    const handleGoBack = () => {
        navigate("/");
    };

    const handleSubRegionClick = (subRegion) => {
        if (subRegion.enabled) {
            navigate(`/regions/${region}/subregion/${subRegion.code}`, {
                state: { listOfCustomers: subRegion.listOfCustomers },
            });
        }
    };

    const handleMouseEnter = (hoveredIndex) => {
        document.querySelectorAll('.square-map-text').forEach((text, index) => {
            text.style.color = index === hoveredIndex
                ? 'rgba(255, 255, 255, 1)'
                : 'rgba(255, 255, 255, 0.5)'; 
        });
    };

    const handleMouseLeave = () => {
        document.querySelectorAll('.square-map-text').forEach((text) => {
            text.style.color = 'rgba(255, 255, 255, 1)';
        });
    };

    return (
        <div className="background-img">
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-between align-items-start w-25 left-side">
                        <div>
                            <img
                                id="first-logo"
                                className="Business-logo"
                                src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
                                alt="Orange Business Logo"
                            />
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-between">
                            <p className="p-0 m-0 text-white global">GLOBAL</p>
                            <p className="p-0 m-0 text-white single-text">
                                SINGLE PANE OF GLASS
                            </p>
                            <div>
                                <img
                                    src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+Global+SPOG+dashboard+-+assets/slice2.png"
                                    alt="Slice"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-75">
                        <div className="orange-border">
                            <div>
                                <div className="map-background">
                                    <div className="map-container">
                                        {subRegions.map((subRegion, index) => (
                                            <div
                                                key={subRegion.code}
                                                className={`square-map ${subRegion.enabled ? 'enabled' : 'disabled'} ${subRegion.code}`}
                                                onClick={() => subRegion.enabled && handleSubRegionClick(subRegion)}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <span className="square-map-text">
                                                    {subRegion.region}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="arrowBackBtn d-flex align-items-center"
                            onClick={handleGoBack}
                        >
                            <i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
                            <p className="BackBtn p-0 m-0">Back</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegionPage;
