import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; 
import { motion } from "framer-motion"; 
import "./subregionpage.css";

const Subregionpage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { listOfCustomers } = location.state || {}; 

	useEffect(() => {
		console.log("Received data:", location);
		const pathSegments = location.pathname.split("/");
		const lastSegment = pathSegments[pathSegments.length - 1]

		const capitalizedRegion = lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
		document.title = `${capitalizedRegion} `;
		
	}, [location.state]);

	// Function to handle navigation
	const handleCustomerClick = (customer) => {
		if (customer.enabled) {
			navigate(`${location.pathname}/${customer.customerName}`, {
				state: {
					listOfuseCases: customer.listOfuseCases, // Pass the listOfuseCases
					customerLogo: customer.customerLogo, // Pass the customer logo
				},
			});
		}
	};
	
	const handleGoBack = () => {
		navigate(-1);
	};

	
	const customerVariants = {
		initial: { opacity: 0, y: 50 },  
		animate: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut", delay: 0.2 } },  
		exit: { opacity: 0, y: -50, transition: { duration: 0.4, ease: "easeIn" } },  
	};

	return (
		<div>
			<div className="subregBackGro">
				<div className="d-flex flex-column algin-item-center">
					<div className="busilogodiv">
						<img
							className="Business-logo"
							src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
							alt="Business Logo"
						/>
					</div>
					<div
						className="subredarrowBackBtn d-flex align-items-center"
						onClick={handleGoBack}
					>
						<i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
						<p className="BackBtn p-0 m-0">Back</p>
					</div>
				</div>
				<div className="regional">
					<div className="regional-img-div">
						<img
							className="regional-img"
							id="rspog-logo"
							src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/slice1%402x.png"
							alt="Regional Logo"
						/>
					</div>
					<div className="listOfCustomers">
						{listOfCustomers &&
							listOfCustomers.map((customer, index) => (
								<motion.div
									key={index}
									className={`customers-detail ${customer.enabled ? "enabled" : ""}`}
									onClick={() => handleCustomerClick(customer)}
									variants={customerVariants}
									initial="initial"
									animate="animate"
									exit="exit"
									whileHover={{
										scale: 1.05,  
										transition: { duration: 0.2 }
									}}
									whileTap={{
										scale: 0.95,  
										transition: { duration: 0.2 }
									}}
								>
									<div className="algin-image">
										<img
											className="customer-list-logo"
											src={customer.customerLogo}
											alt={`${customer.customerName} logo`}
										/>
										{customer.enabled ? (
											<div className="green-btn"></div>
										) : (
											<div className="red-btn"></div>
										)}
									</div>
								</motion.div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subregionpage;
