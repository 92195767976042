import { createClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usersData from "../../LocalData/login.json";
import "./login.css";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const notify = () => toast("Login successful!");

	// Supabase project configuration
	const supabaseUrl = "https://zvsftzgjrbcmxflcsddq.supabase.co";
	const supabaseKey =
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp2c2Z0emdqcmJjbXhmbGNzZGRxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjk4Mzc2MDUsImV4cCI6MjA0NTQxMzYwNX0.JTuWTAvqVQQCgrHnytYqbYum0_mRRlpSHb-sigXkgbU";

	// Create a Supabase client instance
	const supabase = createClient(supabaseUrl, supabaseKey);

	// Function to check if the user is already logged in
	async function checkUserSession() {
		const { data, error } = await supabase.auth.getSession();

		if (error) {
			console.error("Error checking session:", error.message);
			return;
		}

		if (data.session) {
			console.log("User is already logged in:", data.session.user);
			return true;
		} else {
			console.log("No user is currently logged in.");
			return false;
		}
	}

	// Sign-in function
	async function signInUser(email, password) {
		try {
			const { data, error } = await supabase.auth.signInWithPassword({
				email: email,
				password: password,
			});

			if (error) {
				console.error("Error signing in:", error.message);
				setError("Invalid email or password.");
				// alert("Sign-in failed: " + error.message);
				return;
			}

			console.log("User signed in successfully:", data);
			navigate("/");
			setError("");
			// alert("Sign-in successful!");

			// Maintain session (session management handled automatically by Supabase)
			// The client will maintain the user's session and automatically refresh tokens
		} catch (err) {
			console.error("Unexpected error:", err);
		}
	}

	useEffect(() => {
		// If a token exists in localStorage, navigate to the desired route
		const token = localStorage.getItem("authToken");
		// const token = checkUserSession();
		if (token) {
			navigate("/");
		}
	}, [navigate]);

	const handleLogin = (e) => {
		e.preventDefault();
		const user = usersData.find(
			(user) => user.email === email && user.password === password
		);

		if (user) {
			localStorage.setItem("authToken", user.token);
			localStorage.setItem("Name", user.Name);
			navigate("/");
			setError("");
			//   alert("Login successful!");
		} else {
			setError("Invalid email or password.");
		}
	};

	return (
		<div className="login-background">
			<div className="overlay">
				<div className="login-container">
					<img
						className="Business-logo"
						src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
						alt="Logo"
					/>
					<h2 className="login-title">Single Pane of Glass</h2>
					<form onSubmit={handleLogin}>
						<div className="input-group">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								id="email"
								name="email"
								placeholder="Enter your email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
						<div className="input-group">
							<label htmlFor="password">Password</label>
							<input
								type="password"
								id="password"
								name="password"
								placeholder="Enter your password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</div>
						<button
							type="submit"
							onClick={notify}
							className="login-button"
						>
							Log In
						</button>
						{error && <p className="error-message">{error}</p>}
						<p className="forgot-password">Forgot your password?</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
